import { useState, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";

import { Input, Button, Result, Dialog } from "antd-mobile";
import { LockOutline } from 'antd-mobile-icons';

import { useTitle } from "ahooks";

import { Ajax } from "@/api/http.js";
import { GlobalContext } from "@/App.js";

import TopBar from "@/components/topbar.js";

export default function Base(){

    const [isClosed,setIsClosed] = useState(false);
    const [isWxBrowser,setIsWxBrowser] = useState(false);
    const [isVerifyed,setIsVerifyed] = useState(false);

    const [siteName,setSiteName] = useState('友家云相册');
    useTitle(siteName);

    useEffect(()=>{
        checkBrowser();
        checkCloseTime();
        setInterval(checkCloseTime,1000*60);
    },[])

    function checkBrowser(){
        //检查是否微信浏览器
        const userAgent = navigator.userAgent.toLowerCase();
        if(userAgent.includes('micromessenger') || userAgent.includes('wechat')){
            setIsWxBrowser(true);
        }else{
            setIsWxBrowser(false);
        }
    }

    function checkCloseTime(){
        //每天的北京时间2~10期间关闭
        let now=new Date(new Date().getTime()+(parseInt(new Date().getTimezoneOffset()/60) + 8)*3600*1000);
        let startTime=new Date(now.getFullYear(),now.getMonth(),now.getDate(),2,0,0);
        let endTime=new Date(now.getFullYear(),now.getMonth(),now.getDate(),10,0,0);
        if(startTime<=now && now<=endTime){
            setIsClosed(true);
        }else{
            setIsClosed(false);
        }
    }

    if(isWxBrowser){
        return <WxBrowserMsg />
    }

    if(isClosed){
        return <CloseTime />
    }

    if(isVerifyed){
        return (
            <>
                <TopBar />
                <Outlet />
            </>
        )
    }

    return (
        <EnterViewPassword setIsVerifyed={setIsVerifyed} setSiteName={setSiteName} />
    )
}


function WxBrowserMsg(){
    return (
        <h1>请点击右上角，选择在默认浏览器中打开</h1>
    )
}

function CloseTime(){
    return (
        <>
            <Result 
                status="waiting"
                title='网站已打烊'
                description='开放时间：每天上午10点~凌晨2点，请届时再来'
            />
        </>
    )
}

function EnterViewPassword(props){
    const {setGlobalData} = useContext(GlobalContext);
    const [viewPassword,setViewPassword] = useState('');

    //使用查看密码去获得站点配置
    function onGetSiteConfig(){
        if(!viewPassword){
            Dialog.alert({content:'请输入邀请码！'});
            return;
        }
        Ajax('/user/getsiteconfig','post',{
            'viewPassword':viewPassword
        },true,false,true).then(res=>{
            setGlobalData({
                'areas':res['data']['areas'],
                'contacts':res['data']['contacts'],

                'girlDayChargeGold':res['data']['girlDayChargeGold'],
                'girlDayRecommendGold':res['data']['girlDayRecommendGold'],
                'userConfigDays':res['data']['userConfigDays'],
                'userConfigGold':res['data']['userConfigGold'],
                'maxRecommendGirl':res['data']['maxRecommendGirl'],
                'userMsg':res['data']['userMsg'],
                
                'siteName':res['data']['siteName'],
                'siteMessage':res['data']['siteMessage'],

                'userToken':res['data']['userToken'],
                'userId':res['data']['userId'],
                'userName':res['data']['userName'],
                'userCategory':res['data']['userCategory'],
                'userGold':res['data']['userGold'],
                'userConfigIsOpen':res['data']['userConfigIsOpen'],

                'userLng':null,
                'userLat':null,

                'viewPassword':viewPassword
            })

            if(res['data']['userToken']){
                localStorage.setItem('userToken',res['data']['userToken']);
            }

            if(res['data']['siteName']){
                props.setSiteName(res['data']['siteName']);
            }
            props.setIsVerifyed(true);

        }).catch(err=>{
            props.setIsVerifyed(false);
            console.log(err);
        })
    }


    return (
        <div style={{textAlign:'center',margin:'20vh 20vw'}}>
            <LockOutline color="#444" fontSize={120} />
            <div style={{fontSize:'24px'}}>请输入邀请码</div>
            <Input
                placeholder="请输入邀请码" 
                style={{border:'1px solid #000',padding:'2px 10px',margin:'15px 0'}}
                value={viewPassword}
                onChange={(v)=>setViewPassword(v)}
                onEnterPress={()=>onGetSiteConfig()}
            />
            <Button
                color="primary"
                style={{width:'120px'}}
                onClick={()=>onGetSiteConfig()}
            >
                确定
            </Button>
        </div>
    )
}