import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Popup,List, } from "antd-mobile";
import { UnorderedListOutline,UserOutline } from 'antd-mobile-icons';

import { GlobalContext } from "../App.js";

export default function TopBar(){
    const nav = useNavigate();
    
    const {globalData,setGlobalData} = useContext(GlobalContext);
    const [menuVisible,setMenuVisible] = useState(false);

    //菜单栏导航
    function toNav(url){
        setMenuVisible(false);
        nav(url);
    }

    //退出
    function onLogout(){
        localStorage.clear();
        
        let newGlobalData={...globalData};
        newGlobalData['userToken']=null;
        newGlobalData['userId']=null;
        newGlobalData['userName']=null;
        newGlobalData['userCategory']=null;
        newGlobalData['userGold']=null;
        setGlobalData(newGlobalData);

        setMenuVisible(false);
        window.location.reload(false);        
    }

    return (
        <>
            <div style={{
                    height:'50px',
                    lineHeight:'50px',
                    backgroundColor:'#000',
                    color:'#fff',
                    fontSize:'18px',
                    position:'relative'
                }}
            >
                <div style={{
                        position:'absolute',
                        top:'0',
                        left:'10px'
                    }}
                >
                    <UnorderedListOutline onClick={()=>setMenuVisible(true)} />
                </div>
                <div style={{textAlign:'center'}}>
                    <strong onClick={()=>nav('/')} >{globalData.siteName}</strong>
                </div>
            </div>
            <Popup 
                visible={menuVisible} 
                onMaskClick={()=>setMenuVisible(false)}
                position='left'
                bodyStyle={{width:'35vw',height:'100vh'}}
            >
                <List header={<div><UserOutline /> {globalData.userName}</div>}>
                    <List.Item onClick={()=>toNav('/')}>首页</List.Item>
                    {globalData.userToken && (
                        <>
                            <List.Item onClick={()=>toNav('/report')}>统计报表</List.Item>
                            <List.Item onClick={()=>toNav('/girls')}>相册管理</List.Item>
                            <List.Item onClick={()=>toNav('/userconfig')}>客服设置</List.Item>
                            <List.Item onClick={()=>toNav('/golds')}>我的金币</List.Item>
                            <List.Item onClick={()=>toNav('/user/changepwd')}>修改密码</List.Item>
                            <List.Item onClick={()=>toNav('/share')}>分享</List.Item>
                        </>
                    )}
                    {globalData.userCategory==0 && (
                        <>
                            <List.Item onClick={()=>toNav('/sysconfig')}>系统设置</List.Item>
                            <List.Item onClick={()=>toNav('/users')}>用户管理</List.Item>
                            <List.Item onClick={()=>toNav('/charges')}>充值记录</List.Item>
                            <List.Item onClick={()=>toNav('/userlogs')}>操作记录</List.Item>
                        </>
                    )}
                    {globalData.userToken ? (
                        <List.Item onClick={onLogout}>退出</List.Item>
                    ) : (
                        <List.Item onClick={()=>{toNav('/user/login')}}>登陆</List.Item>
                    )}
                </List>
            </Popup>
        </>
    )
}